<template>
  <el-container>
    <el-main v-if="orderInfo.account_info">
      <el-row>
        <el-col class="name" :span="5"><div>应用名称：</div></el-col>
        <el-col :span="19">
          <div>{{ orderInfo.account_info.name }}</div>
        </el-col>
      </el-row>
      <el-row>
        <el-col class="name" :span="5"><div>续费版本：</div></el-col>
        <el-col :span="19">
          <div>{{ orderInfo.case_info.name }}</div>
        </el-col>
      </el-row>
      <el-row>
        <el-col class="name" :span="5"><div>版本描述：</div></el-col>
        <el-col :span="19">
          <div>{{ orderInfo.case_info.name }}本套餐</div>
        </el-col>
      </el-row>
      <el-row>
        <el-col class="name" :span="5"><div>续费价格：</div></el-col>
        <el-col :span="19">
          <div>￥{{ orderInfo.case_info.price }}</div>
        </el-col>
      </el-row>
      <el-row>
        <el-col class="name" :span="5"><div>续费天数：</div></el-col>
        <el-col :span="19">
          <div>{{ orderInfo.case_info.timelimit }}天</div>
        </el-col>
      </el-row>
      <el-row class="el-row6">
        <el-col class="name" :span="5"><div>选择支付方式：</div></el-col>
        <el-col :span="19">
          <div class="pay-type">
            <div @click="type = 2" :class="[type == 2 ? 'pay-type-active' : '']">
              <img v-show="type == 2" class="jiao" src="../../assets/image/jiao.png" alt="" />
              <img src="../../assets/image/Alipay.png" alt="" />
              支付宝支付
            </div>
            <div @click="type = 1" :class="[type == 1 ? 'pay-type-active' : '']">
              <img v-show="type == 1" class="jiao" src="../../assets/image/jiao.png" alt="" />
              <img src="../../assets/image/weixin.png" alt="" />
              微信支付
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" style="padding-left: 190px">
          <div class="shopRenew">
            <el-button type="primary" @click="payment" size="small">立即续费</el-button>
            <router-link to="/shop/index">
              <el-button size="small">返回</el-button>
            </router-link>
          </div>
        </el-col>
      </el-row>
    </el-main>
    <!-- 支付 -->
    <el-dialog class="paymentDialog" :title="type == 1 ? '微信支付' : '支付宝支付'" center :visible.sync="is_showPay" :before-close="handleClose" width="30%">
      <div class="content">
        <div class="qrcode">
          <div id="qrcode" ref="qrcode"></div>
          <div class="success" v-show="is_success">
            <i class="el-icon-check"></i>
          </div>
        </div>
        <div class="money">
          <p>支付金额：{{ payPirce }}元</p>
          <p>请使用{{ type == 1 ? '微信' : '支付宝' }}扫一扫，扫描二维码进行支付</p>
        </div>
        <div class="tips">
          <p>支付完成前请不要关闭此窗口</p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleClose">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
import QRCode from 'qrcodejs2';
export default {
  data() {
    return {
      payPirce: '',
      is_showPay: !1,
      type: 2,
      orderInfo: {},
      queryOrderTime: 1000,
      is_success: !1,
    };
  },
  created() {
    this.$axios
      .post(this.$api.site.getPreOrder, {
        uniacid: this.$route.query.id,
      })
      .then(res => {
        if (res.code == 0) {
          this.orderInfo = res.result;
        } else {
          this.$message.error(res.msg);
        }
      });
  },
  methods: {
    handleClose() {
      this.is_showPay = !1;
      clearTimeout(this.timer);
      this.queryOrderTime = 1000;
    },
    //检测站点套餐支付状态
    orderFind() {
      this.$axios
        .post(this.$api.user.orderFind, {
          order_id: this.orderInfo.order_id,
          pay_type: this.type,
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('支付成功');
            this.is_success = !0;
            setTimeout(() => {
              this.$router.push('/shop');
            }, 2000);
          } else {
            this.queryOrderTime = this.queryOrderTime + 1000;
            this.timer = setTimeout(() => {
              this.orderFind();
            }, this.queryOrderTime);
          }
        });
    },
    //支付
    payment() {
      this.payPirce = this.orderInfo.case_info.price;
      this.$axios
        .post(this.$api.site.renewPay, {
          pay_type: this.type,
          order_id: this.orderInfo.order_id,
        })
        .then(res => {
          if (res.code == 0) {
            this.is_showPay = !0;
            setTimeout(() => {
              this.$refs.qrcode.innerHTML = '';
              let qr_code = '';
              if (this.type == 1) {
                qr_code = res.result;
              } else {
                qr_code = res.result.qr_code;
              }
              let qrcode = new QRCode('qrcode', {
                width: 132,
                height: 132,
                text: qr_code, // 二维码地址
                colorDark: '#000',
                colorLight: '#fff',
              });
            }, 100);
            setTimeout(() => {
              this.orderFind();
            }, this.queryOrderTime);
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-container {
  flex-direction: column;
  align-items: center;
  /deep/ .paymentDialog .el-dialog__header {
    text-align: left !important;
  }
  /deep/ .paymentDialog .el-dialog__body {
    padding: 25px 25px 0px;
  }
  .paymentDialog .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .qrcode {
      margin-bottom: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .success {
        position: absolute;
        top: 0;
        left: 0;
        width: 132px;
        height: 132px;
        background: rgba(0, 0, 0, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          font-size: 70px;
          font-weight: bold;
          color: #2fb72f;
        }
      }
    }
    .money {
      text-align: center;
      p:nth-child(1) {
        color: orange;
        font-size: 16px;
      }
      p:nth-child(2) {
        color: #868686;
        font-size: 12px;
        margin-top: 10px;
      }
    }
    .tips {
      color: #868686;
      font-size: 14px;
      line-height: 40px;
      margin-top: 20px;
      border-top: 1px dashed #e6e6e6;
      width: 100%;
      text-align: center;
    }
  }
  .el-main {
    width: 950px;
    height: 505px;
    padding-top: 40px;
    background: #fff;
    margin-top: 20px;
    .el-row6 {
      display: flex;
      align-items: center;
    }
    .el-row {
      margin-bottom: 40px;
      font-size: 14px;
      .name {
        text-align: right;
      }
      .pay-type {
        display: flex;

        div {
          width: 150px;
          height: 50px;
          border: 1px solid #e4e4e4;
          margin-right: 10px;
          text-align: center;
          font-size: 18px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          img {
            width: 25px;
            height: 25px;
            margin-right: 5px;
          }
          .jiao {
            position: absolute;
            z-index: 2;
            right: 0;
            bottom: 0;
            width: 24px;
            height: 20px;
            margin: 0;
          }
        }
        .pay-type-active {
          border: 2px solid #ff4544;
        }
      }
      .shopRenew .el-button:last-child {
        margin-left: 10px;
      }
    }
  }
}
</style>
